import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ReportsStore } from '../../store/reports/Reports'
import { TableFiltersBlock } from '../TableFiltersBlock/TableFiltersBlock'
import ListItem from "@material-ui/core/ListItem";
import { IReportFilters, IRaportMonth } from '../../store/reports/types'
import { Tooltip, Table, TableColumnsType, Pagination } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { MobileGoCountRaports } from './components/MobileGoCountRaport';
import { Link, useHistory } from 'react-router-dom';
import { formatIsoToDate, formatIsoToTime } from '../helpers/utils';
import { openNotification } from '../helpers/notification';
const s = require('./style.module.scss')

interface IGoCountRaportProps {

}

export const GoCountRaport: React.FC<IGoCountRaportProps> = observer(({ }) => {
    const {
        getGoCountRaport,
        goCountRaport,
        goCountRaportLoading
    } = ReportsStore

    const requestHandler = (payload: IReportFilters) => {
        getGoCountRaport({...payload, isCancled: false});
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mobile, setMobile] = useState(windowWidth > 800 ? false : true)

    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(25)

    const [dateStartProp, setDateStartProp] = useState<string | undefined>(undefined)
    const [dateEndProp, setDateEndProp] = useState<string | undefined>(undefined)


    useEffect(() => {
        requestHandler({})
    }, [])

        //Для таблицы
        const scroll: { x?: number | string; y?: number | string } = { x: 2000, y: 700 };
        const defaultTitle = () => 'Кол-во выездов (рапорт)';
    
        const history = useHistory()
        // interface DataType {
        //     key?: string | number
        //     pult: string
        //     name: string
        //     address: string
        //     fake: string
        //     combat: string
        //     count: string
        //     territory: string
        //     gbrs_string: string
        // }
    
        // const columns: any = goCountRaport?.headers.map((element: any, index: number) => {
        //     return ({
        //         key: element.name,
        //         title: element.name,
        //         dataIndex: element.mnemo,
        //         ellipsis: {
        //             showTitle: false,
        //         },
        //         width: 180,
        //         render: (address: any) => {
        //             return (
        //                 <Tooltip trigger="hover" placement="topLeft" title={typeof address === 'string' ? address : ''}>
        //                     {typeof address === 'string' ? address : ''}
        //                 </Tooltip>
        //             )
        //         },
        //     });
        // }) || [];
    
        // const dataSource: DataType[] = goCountRaport?.data
        //     ? goCountRaport.data.map((element: any, index: number) => {
        //         return {
        //             key: element.id,
        //             pult: element?.pult,
        //             name: element?.name,
        //             address: element?.address,
        //             fake: element?.fake,
        //             combat: element?.combat,
        //             count: element?.count,
        //             territory: element?.territory,
        //             gbrs_string: element?.gbrs_string
        //         };
        //     })
        //     : [];

        interface DataType {
            "pultNumber": string,
            "object": string,
            "objectAdress": string,
            "goCount": string,
            "last_departure": string,
            "fake": string,
                "fakeChildren": {
                    "TP_count": string,
                    "VK_count": string,
                    "NP_count": string
                }
            "fake_result": string,
            "combat": string,
                "combatChildren": {
                    "PZ_count ": string,
                    "PbZ_count ": string,
                    "BP_count ": string,
                }
            "combat_result": string,
            "territory": string,
            "system_name": string,
            "gbrs_string": string,         
        }

        const handleOpenNewTab = (result: any, dateStart: any, dateEnd: any) => {
            if(dateStart && dateEnd){
                const paramsResult = new URLSearchParams(result).toString();
                const paramsDateStart = new URLSearchParams(dateStart).toString();
                console.log(paramsDateStart)
                const paramsDateEnd = new URLSearchParams(dateEnd).toString();
                const url = `/raports?paramsResult=${result}&paramsDateStart=${dateStart}&paramsDateEnd=${dateEnd}`
                console.log(url)
                //@ts-ignore
                window.open(url, '_blank');
            }else{
                openNotification({
                    type: 'warning',
                    message: 'Выберите даты'
                })
            }
        }   

        const columns: TableColumnsType<any> = [
            {
                title: "Пультовый номер",
                dataIndex: "pultNumber",
                key: "pultNumber",
                width: 130,
                fixed: "left",
                ellipsis: {
                    showTitle: false,
                },
                render: (text: string) => 
                 
                <a onClick={() => handleOpenNewTab(text, JSON.stringify(dateStartProp), JSON.stringify(dateEndProp) )}>{text}</a>,
                  
                
                    // <Link 
                        // to={{pathname: '/raports',
                        //      state: {results: text, dateStart: JSON.stringify(dateStartProp), dateEnd: JSON.stringify(dateEndProp)}
                        //     }} 
                        //     target="_blank">
                        //     {text}
                        // </Link>

                // <a target={"__blank"} onClick={() => history.push({pathname: '/raports', state: {results: text, dateStart: JSON.stringify(dateStartProp), dateEnd: JSON.stringify(dateEndProp)}})}>{text}</a>,
            },
            {
                title: "Объект",
                dataIndex: "object",
                key: "object",
                width: 130,
                ellipsis: {
                    showTitle: false,
                },
                render: (address: any) => {
                    return (
                        <Tooltip trigger="hover" placement="topLeft" title={typeof address === 'string' ? address : ''}>
                            {typeof address === 'string' ? address : ''}
                        </Tooltip>
                    )
                },
            },
            {
                title: "Адрес объекта",
                dataIndex: "objectAdress",
                key: "objectAdress",
                width: 130,
                ellipsis: {
                    showTitle: false,
                },
                render: (address: any) => {
                    return (
                        <Tooltip trigger="hover" placement="topLeft" title={typeof address === 'string' ? address : ''}>
                            {typeof address === 'string' ? address : ''}
                        </Tooltip>
                    )
                },
            },
            {
                title: "Кол-во выездов",
                dataIndex: "goCount",
                key: "goCount",
                width: 130,
                ellipsis: {
                    showTitle: false,
                },
                render: (address: any) => {
                    return (
                        <Tooltip trigger="hover" placement="topLeft" title={typeof address === 'string' ? address : ''}>
                            {typeof address === 'string' ? address : ''}
                        </Tooltip>
                    )
                },
            },
            {
                title: "Последний выезд",
                dataIndex: "last_departure",
                key: "last_departure",
                width: 130,
                ellipsis: {
                    showTitle: false,
                },
                render: (address: any) => {
                    return (
                        <Tooltip trigger="hover" placement="topLeft" title={typeof address === 'string' ? address : ''}>
                            {typeof address === 'string' ? address : ''}
                        </Tooltip>
                    )
                },
            },
            {
                title: "Ложные",
                // dataIndex: "fake",
                // key: "fake",
                // fixed: "left",
                children: [
                    {
                        title: "ТП",
                        dataIndex: "fakeChildren_TP_count",
                        key: "fakeChildren_TP_count",
                        width: 70,
                    },
                    {
                        title: "ВК",
                        dataIndex: "fakeChildren_VK_count",
                        key: "fakeChildren_VK_count",
                        width: 70,
                    },
                    {
                        title: "НП",
                        dataIndex: "fakeChildren_NP_count",
                        key: "fakeChildren_NP_count",
                        width: 70,
                    },
                    {
                        title: "Итого",
                        dataIndex: "fake_result",
                        key: "fake_result",
                        width: 70,
                    },
                ]
            },

            {
                title: "Боевые",
                // dataIndex: "combat",
                // key: "combat",
                // fixed: "left",
                children: [
                    {
                        title: "ПЗ",
                        dataIndex: "combatChildren_PZ_count",
                        key: "combatChildren_PZ_count",
                        width: 70,
                    },
                    {
                        title: "ПбЗ",
                        dataIndex: "combatChildren_PbZ_count",
                        key: "combatChildren_PbZ_count",
                        width: 70,
                    },
                    {
                        title: "БП",
                        dataIndex: "combatChildren_BP_count",
                        key: "combatChildren_BP_count",
                        width: 70,
                    },
                    {
                        title: "Итого",
                        dataIndex: "combat_result",
                        key: "combat_result",
                        width: 70,
                    },
                ]
            },

            {
                title: "Территория",
                dataIndex: "territory",
                key: "territory",
                width: 130,
                ellipsis: {
                    showTitle: false,
                },
                render: (address: any) => {
                    return (
                        <Tooltip trigger="hover" placement="topLeft" title={typeof address === 'string' ? address : ''}>
                            {typeof address === 'string' ? address : ''}
                        </Tooltip>
                    )
                },
            },
            {
                title: "Система",
                dataIndex: "system_name",
                key: "system_name",
                width: 130,
                ellipsis: {
                    showTitle: false,
                },
                render: (address: any) => {
                    return (
                        <Tooltip trigger="hover" placement="topLeft" title={typeof address === 'string' ? address : ''}>
                            {typeof address === 'string' ? address : ''}
                        </Tooltip>
                    )
                },
            },
            {
                title: "ГБР",
                dataIndex: "gbrs_string",
                key: "gbrs_string",
                width: 130,
                ellipsis: {
                    showTitle: false,
                },
                render: (address: any) => {
                    return (
                        <Tooltip trigger="hover" placement="topLeft" title={typeof address === 'string' ? address : ''}>
                            {typeof address === 'string' ? address : ''}
                        </Tooltip>
                    )
                },
            },
        ]
        const data = goCountRaport?.data
            ? goCountRaport.data.map((element: any, index: number) => {
                return {
                    pultNumber: element?.pult,
                    object: element?.name,
                    objectAdress: element?.address,
                    goCount: element?.count,
                    last_departure: element?.last_departure && formatIsoToDate(element?.last_departure) + ` ` + formatIsoToTime(element?.last_departure),
                    fakeChildren_TP_count: element?.TP_count,
                    fakeChildren_VK_count: element?.VK_count,
                    fakeChildren_NP_count: element?.NP_count,
                    fake_result: element?.fake,
                    combatChildren_PZ_count: element?.PZ_count,
                    combatChildren_PbZ_count: element?.PbZ_count,
                    combatChildren_BP_count: element?.BP_count,
                    combat_result: element?.combat,
                    territory: element?.territory,
                    system_name: element?.system_name,
                    gbrs_string: element?.gbrs_string
                };
            })
            : [];
            const resultData = data?.pop()
            data?.push({
                pultNumber: resultData?.pultNumber,
                object: resultData?.object,
                objectAdress: "Итого",
                goCount: resultData?.goCount,
                last_departure: resultData?.last_departure && formatIsoToDate(resultData?.last_departure) + ` ` + formatIsoToTime(resultData?.last_departure),
                fakeChildren_TP_count: resultData?.fakeChildren_TP_count,
                fakeChildren_VK_count: resultData?.fakeChildren_VK_count,
                fakeChildren_NP_count: resultData?.fakeChildren_NP_count,
                fake_result: resultData?.fake_result,
                combatChildren_PZ_count: resultData?.combatChildren_PZ_count,
                combatChildren_PbZ_count: resultData?.combatChildren_PbZ_count,
                combatChildren_BP_count: resultData?.combatChildren_BP_count,
                combat_result: resultData?.combat_result,
                territory: resultData?.territory,
                system_name: resultData?.system_name,
                gbrs_string: resultData?.gbrs_string
            })
            const [activeTable, setActiveTable] = useState<boolean>(false)
            return <div className={s.container}>
            {/* {openPopup && <InfoModal
                closeHandler={() => setOpenPopup(false)}goCountGroup
                i={clickedItem}
                open={openPopup}
                arrData={arrData}
                title={`Рапорт по тревоге  - ${clickedItem?.object_name}`}
            />} */}
            <div className={s.container__header}>
                <TableFiltersBlock 
                flag={'GoCountRaport'} 
                xlsxFlag='DepartureCountReport' 
                request={requestHandler} 
                setActiveTable={setActiveTable} 
                setDateStartFilter = {setDateStartProp}
                DateStartFilter = {dateStartProp} 
                setDateEndFilter = {setDateEndProp}
                DateEndFilter= {dateEndProp} />
            </div>
            {
            mobile ? <MobileGoCountRaports
                // onItemClick={onItemClick}
                // onClickGetPdf={onClickGetPdf}
                dateStartProp={dateStartProp}
                dateEndProp={dateEndProp}
                handleOpenNewTab={handleOpenNewTab}
                items={goCountRaport?.data}
                loading={goCountRaportLoading}
            />
                :
            <div className={s.smallTable}>
                        <Table
                            size='small'
                            rowClassName={s.tableRow}
                            columns={columns}
                            dataSource={data}
                            bordered
                            scroll={scroll}
                            loading={goCountRaportLoading}
                            pagination={{ position: ['topLeft', 'bottomLeft'], pageSizeOptions: [25, 50, 75, 100], defaultPageSize: 25 }}
                        />
                        </div>
            }
        </div>
})