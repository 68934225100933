import React, { useEffect } from 'react'
import { Card, List, Space,  Table,  Tooltip } from 'antd';
import pdf from '../../../icons/pdficon.svg'
import { formatIsoToDate, formatIsoToTime } from '../../helpers/utils';

interface IMobileSettings {
    items : any
    // onItemClick : (element : any) => void
    loading: any
    setOpenModal: Function
    setTerritoryInfo: Function
  }

export const MobileSettings: React.FC<IMobileSettings> = ({items, loading, setOpenModal, setTerritoryInfo}) => {

  const titleCard = (name: string) => {
    return (
        <Tooltip trigger="hover" placement="topLeft" title={typeof name === 'string' ? name : ''}>
            {name ? name : '-'}
        </Tooltip>
    )
}

console.log(items)


  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
     <List pagination={{ position: "both", pageSizeOptions: [25, 50, 75, 100], defaultPageSize: 25 }}
            dataSource = {items ? items : []}
            loading = {loading}
            renderItem={(element: any) => {
                interface DataType {
                    "gbrName": string  
                }
                const columns = [
                    {
                    
                        title: 'Название ГБР',
                        dataIndex: 'name',
                        key: 'name',
                        
                    }
                ]
                
                const data: DataType[] = element?.gbrs?.map((element: any) => {
                    return (
                        {
                            name: element?.gbrName,
                        }
                    )
                })
                const onClick = () => {

                }
                return(
                <Card loading={loading} 
                actions={[<div onClick={() => 
                setOpenModal({open: true, flag: 'post'})}>Редактировать</div>, <div onClick={() => setOpenModal({open: true, flag: 'delete'})}>Удалить</div>]} style={{margin: '15px 0 15px 0'}} key={element.id} title={titleCard(element.territoryName)} size='default'>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            {/* <div style={{ fontWeight: 'bold' }}>ГБР :</div> */}
                            <div>
                                {element?.gbrs?.length === 0 ? <> </> :
                                    
                                    <Table
                                        // onRow={(record, rowIndex) => {
                                        //     return {
                                        //         onClick: () => {
                                        //             setTerritoryInfo(record)
                                        //         }
                                        //     }
                                        // }}
                                        size='large'
                                        // rowClassName={s.tableRow}
                                        columns={columns}
                                        dataSource={data}
                                        bordered
                                        pagination={false}
                                    />
                                }
                                

                          
                            </div>
                        </div>


                </Card>
            )}}
            />
    </Space>
  )
}