import React from 'react'
import { Card, List, Space,  Tooltip } from 'antd';
import pdf from '../../../icons/pdficon.svg'

interface IMobileGoCountRaportsProps {
    items : any
    loading: any
    dateStartProp: any
    dateEndProp: any
    handleOpenNewTab: Function
  }

export const MobileGoCountRaports: React.FC<IMobileGoCountRaportsProps> = ({items, loading, dateStartProp, dateEndProp, handleOpenNewTab}) => {

  const titleCard = (name: string) => {
    return (
        <Tooltip trigger="hover" placement="topLeft" title={typeof name === 'string' ? name : ''}>
            {name ? name : '-'}
        </Tooltip>
    )
}

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
        <List pagination={{ position: "both", pageSizeOptions: [25, 50, 75, 100], defaultPageSize: 25 }}
            dataSource = {items}
            loading = {loading}
            renderItem={(element: any) => (
                <Card style={{margin:'15px 0 15px 0'}} key={element.id} title={titleCard(element.name)} size='default'>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Пульт :</div>
                            <div>
                                <a onClick={() => handleOpenNewTab(element?.pult, JSON.stringify(dateStartProp), JSON.stringify(dateEndProp) )}>{element?.pult}</a>,
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Наименование :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.name === 'string' ? element?.name : ''}>
                                    {element?.name ? element?.name.split('').length >= 20 ? `${element?.name.substr(0, 20)}...` : element?.name : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Адрес :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.address === 'string' ? element?.address : ''}>
                                    {element?.address ? element?.address.split('').length >= 20 ? `${element?.address.substr(0, 20)}...` : element?.address : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Ложные :</div>
                            <div>
                                {element?.fake ? element?.fake : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Боевые :</div>
                            <div>
                                {element?.combat ? element?.combat : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Территория :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.territory === 'string' ? element?.territory : ''}>
                                    {element?.territory ? element?.territory.split('').length >= 20 ? `${element?.territory.substr(0, 20)}...` : element?.territory : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Территория :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.territory === 'string' ? element?.territory : ''}>
                                    {element?.territory ? element?.territory.split('').length >= 20 ? `${element?.territory.substr(0, 20)}...` : element?.territory : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>ГБР :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.gbrs_string === 'string' ? element?.gbrs_string : ''}>
                                    {element?.gbrs_string ? element?.gbrs_string.split('').length >= 20 ? `${element?.gbrs_string.substr(0, 20)}...` : element?.gbrs_string : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Всего :</div>
                            <div>
                                {element?.count ? element?.count : '-'}
                            </div>
                        </div>
                </Card>
            )}   
                
          />
    </Space>
  )
}