// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_downloadBtn__1xscc {\n  margin-top: 16px;\n}\n.style_downloadBtn__1xscc a {\n  background: black;\n  color: white;\n  padding: 4px 8px;\n  border-radius: 4px;\n}\n.style_downloadBtn__1xscc a:hover {\n  color: white !important;\n}\n\n.style_footerRow__1jX37 {\n  display: grid;\n  grid-template-columns: 180px 180px;\n  font-weight: 700;\n  margin: 0;\n}\n.style_footerRow__1jX37 div {\n  padding: 16px;\n}\n\n.style_antTableFooter__39JMd {\n  padding: 0;\n}", "",{"version":3,"sources":["webpack://src/components/GoCountRaport/style.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAAI;EACI,iBAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AAER;AADQ;EACI,uBAAA;AAGZ;;AASA;EACI,aAAA;EACA,kCAAA;EACA,gBAAA;EACA,SAAA;AANJ;AAOI;EACE,aAAA;AALN;;AASE;EACE,UAAA;AANJ","sourcesContent":[".downloadBtn {\n    margin-top: 16px;\n    a {\n        background: black;\n        color: white;\n        padding: 4px 8px;\n        border-radius: 4px;\n        &:hover {\n            color: white !important\n        }\n    }\n}\n\n// .tableRow {\n//   overflow: hidden;\n//   white-space: nowrap;\n//   text-overflow: ellipsis;\n//   box-sizing: border-box;\n// }\n\n.footerRow {\n    display: grid;\n    grid-template-columns: 180px 180px;\n    font-weight: 700;\n    margin: 0;\n    div {\n      padding: 16px;\n    }\n  }\n  \n  .antTableFooter {\n    padding: 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadBtn": "style_downloadBtn__1xscc",
	"footerRow": "style_footerRow__1jX37",
	"antTableFooter": "style_antTableFooter__39JMd"
};
export default ___CSS_LOADER_EXPORT___;
