import { ListItem } from '@material-ui/core'
import moment from 'moment'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { SimpleInput } from '../helpers/Inputs/inputs'
import { MonthPicker, RangeDatePicker } from '../helpers/RangeDatePicker/DatePickerComponent'
import { SimpleSelect } from '../helpers/Selects/SimpleSelect'
import { IReportFilters } from '../../store/reports/types'
import { observer } from 'mobx-react-lite'
import { ReportsStore } from '../../store/reports/Reports'
import { DatePicker, Input, TimePicker } from 'antd'
import { SimpleDatepicker } from '../common/SImpleDatePicker/SimpleDatePicker'
import { formatIsoToDate } from '../helpers/utils'
import { openNotification } from '../helpers/notification'
import { SimpleCheckbox } from '../common/CheckBox/CheckBox'
import useDebouncedFunction from '../helpers/useDebounceFunction'
import { getHeapCodeStatistics } from 'v8'
import { useLocation, useParams } from 'react-router-dom'

const s = require('./TableFiltersBlock.module.scss')

interface ITableFiltersBlockProps {
    flag?: string
    request: (payload: IReportFilters) => void,
    xlsxFlag: string
    buttonFlag?: string
    setActiveTable: Function
    activeTable?: boolean
    setOpenModal?: Function
    settingsLoading?: boolean
    setDateStartFilter?: Function
    setDateEndFilter?: Function
    DateStartFilter?:string
    DateEndFilter?: string
}

interface LocationState {
    results: any
    dateStart?: string
    dateEnd?: string
  }

export const TableFiltersBlock: React.FC<ITableFiltersBlockProps> = observer(({ flag, request, xlsxFlag, buttonFlag, setActiveTable, activeTable, setOpenModal, settingsLoading, setDateStartFilter, setDateEndFilter, DateEndFilter, DateStartFilter }) => {

    const {
        getGBRS,
        gbrList,
        gbrListLoading,
        getPCO,
        pcoList,
        pcoListLoading,
        getReportXlSX,
        getEventTypes,
        eventTypes,
        eventTypesLoading,
        getAlarmKindTypes,
        alarmKindTypes,
        alarmKindTypesLoading,
        chopReports,
        chopReportsLoadnig,
        getReportChops,
        addressOfRaports,
        addressOfRaportsLoading,
        objectsOfRaports,
        objectsOfRaportsLoading,
        pultsOfRaports,
        pultsOfRaportsLoading,
        getAddressesOfRaports,
        getObjectsOfRaports,
        getPultsOfRaports,
        getSettings,
        settings,
    } = ReportsStore


    const hrefName = window.location.href.split('/')[window.location.href.split('/').length - 1]
    const [dateStart, setDateStart] = useState<string | undefined>(undefined)
    const [dateEnd, setDateEnd] = useState<string | undefined>(undefined)
    const [timeStart, setTimeStart] = useState<string | undefined>(undefined)
    const [timeEnd, setTimeEnd] = useState<string | undefined>(undefined)
    const [showTime, setShowTime] = useState<boolean>(false)
    const [object, setObject] = useState<string | undefined>(undefined)
    const [objectAddress, setObjectAddress] = useState<string | undefined>(undefined)
    const [gbr, setGbr] = useState<string | undefined>(undefined)
    const [pult, setPult] = useState<string | undefined>(undefined)
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
    const [event, setEvent] = useState<string | number | undefined>(undefined)
    const [alarmKind, setAlarmKind] = useState<string | number | undefined>(undefined)
    const [isDeleted, setIsDeleted] = useState<boolean>(false)
    const [isMonth, setIsMonth] = useState<boolean>(false)
    const [isGroupCount, setIsGroupCount] = useState<boolean>(false)
    const [isExternal, setIsExternal] = useState<boolean>((hrefName === 'goExternalObject' || hrefName === 'goCountChop') ? true : false)
    const [oneDate, setOneDate] = useState<Date | undefined>(new Date())
    const [chop, setChop] = useState<string | undefined>(undefined)
    const [countFrom, setCountFrom] = useState<string | undefined>(undefined)
    const [countTo, setCountTo] = useState<string | undefined>(undefined)
    const [gbrId, setGbrId] = useState<string | undefined>(undefined)
    const [pcoId, setPcoId] = useState<string | undefined>(undefined)
    const [cancled, setCancled] = useState<boolean>(false)
    const [territoryId, setTerritoryId ] = useState<string | undefined>(undefined)
    const [isLessTwoHours, setIsLessTwoHours] = useState<boolean>(false)
    const [isNoDeparture, setIsNoDeparture] = useState<boolean>(false)
    const [minDepartureCount, setMinDepartureCount] = useState<string | number | undefined>(undefined)
    const location = useLocation<LocationState>();

    // const pultState: any = location.state?.results || null
    // const dateS: any = location.state?.dateStart || null
    // const dateE: any = location.state?.dateEnd || null
    
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    const pultState = params.get("paramsResult") || undefined
    const dateS = params.get("paramsDateStart") || undefined 
    const dateE = params.get("paramsDateEnd") || undefined

    // console.log(param1)
    // console.log(param2)
    // console.log(param3)

    useEffect(() => {
        console.log(DateEndFilter)
        console.log(DateStartFilter)
        console.log(dateS)
        console.log(dateE)
        // console.log(dateEnd)
        // console.log(dateStart)
    }, [DateEndFilter, DateStartFilter, dateE, dateS])
    // console.log(pultState?.results)
    // const [searchValue, setSearchValue] = useState<string | undefined>(undefined)

    const resetFiltersHandler = () => {
        setTimeEnd(undefined)
        setTimeStart(undefined)
        setDateStart(undefined)
        setDateEnd(undefined)
        setDateStartFilter && setDateStartFilter(undefined)
        setDateEndFilter && setDateEndFilter(undefined)
        setCountFrom(undefined)
        setCountTo(undefined)
        setGbrId(undefined)
        setObjectAddress(undefined);
        setGbr(undefined)
        setPult(undefined)
        setObject(undefined)
        setSearchValue('')
        setIsDeleted(false)
        setIsExternal(false)
        setEvent(undefined)
        setAlarmKind(undefined)
        setChop(undefined)
        setPcoId(undefined)
        setCancled(false)
        setTerritoryId(undefined)
        setIsLessTwoHours(true)
        setIsNoDeparture(false)
    }
    const formattedTimeStart = moment(timeStart).startOf('m').format("HH:mm");
    const formattedTimeEnd = moment(timeEnd).startOf('m').format("HH:mm");
    const formattedStartDate = moment(dateStart).startOf('m').format("YYYY-MM-DDTHH:mm").slice(0, -5) + formattedTimeStart;
    const formattedEndTime = moment(dateEnd).endOf('m').format("YYYY-MM-DDTHH:mm").slice(0, -5) + formattedTimeEnd;

    //dateE и dateS берутся из стэйт значение history, который берется от сюда и кидается сюда же, через компоненту кол-во выездов
    
    const formattedTimeStartFromGoCounnt = moment(timeStart).startOf('m').format("HH:mm");
    const formattedTimeEndFromGoCounnt = moment(timeEnd).startOf('m').format("HH:mm");
    const formattedStartDateFromGoCounnt = dateS && moment(JSON.parse(dateS)).startOf('m').format("YYYY-MM-DDTHH:mm").slice(0, -5) + formattedTimeStart;
    const formattedEndTimeFromGoCounnt = dateE && moment(JSON.parse(dateE)).endOf('m').format("YYYY-MM-DDTHH:mm").slice(0, -5) + formattedTimeEnd;

    useEffect(() => {
        if(dateEnd !== undefined){
            setShowTime(true)
        }
        if(dateEnd == undefined){
            setShowTime(false)
        }
    },[dateEnd])

    const downloadXLSX = () => {  
        if(xlsxFlag === 'JournalActionsReport') 
            {
                getReportXlSX({
                    reportName: (hrefName === 'raports' && isExternal) ? 'ExternalRaportsReport' : xlsxFlag,
                    fileExtension: 'xlsx',
                    paramList: [{
                        name: 'request',
                        value: {
                            pcoId,
                            gbrId,
                            isExternal: isExternal,
                            startDate: hrefName === 'goCountGroup' ? oneDate?.toISOString() : timeStart === undefined ? formattedStartDate.slice(0, -5) + "00:00" : formattedStartDate,
                            endDate: timeEnd === undefined ? formattedEndTime.slice(0, -5) + "23:59" : formattedEndTime,
                            address: objectAddress,
                            searchValue: pult,
                            isDeleted,
                            objectName: object,
                            // pultNumber: pult,
                            eventType: event,
                            gbr,
                            alarmKindId: alarmKind,
                            chopName: chop,
                            isWebReport : true,
                            isCancled: cancled
                        }
                    }]
                })
            } else if(xlsxFlag === 'DepartureReport') {
            getReportXlSX({
                    reportName: xlsxFlag,
                    fileExtension: 'xlsx',
                    paramList: [{
                        name: 'request',
                        // @ts-ignore
                        value: {
                            startDate: timeStart === undefined ? formattedStartDate.slice(0, -5) + "00:00" : formattedStartDate,
                            endDate: timeEnd === undefined ? formattedEndTime.slice(0, -5) + "23:59" : formattedEndTime,
                            territoryId,
                            gbrId,
                            isOnlyExternal: isExternal
                        }
                    }]
                })
            } else if(xlsxFlag === 'DepartureCountReport') {
                getReportXlSX({
                        reportName: xlsxFlag,
                        fileExtension: 'xlsx',
                        paramList: [{
                            name: 'request',
                            // @ts-ignore
                            value: {
                                startDate: timeStart === undefined ? formattedStartDate.slice(0, -5) + "00:00" : formattedStartDate,
                                endDate: timeEnd === undefined ? formattedEndTime.slice(0, -5) + "23:59" : formattedEndTime,
                                gbrId,
                                territoryId,
                                isGroupCount,
                                isOnlyExternal: isExternal,
                                minDepartureCount: minDepartureCount === undefined ? undefined :  Number(minDepartureCount)
                            }
                        }]
                    })
                }else if (xlsxFlag === 'ExternalRaportsReport'){
                    getReportXlSX({
                        reportName: (hrefName === 'raports' && isExternal) ? 'ExternalRaportsReport' : xlsxFlag,
                        fileExtension: 'xlsx',
                        paramList: [{
                            name: 'request',
                            value: {
                                pcoId,
                                gbrId,
                                isExternal: isExternal,
                                startDate: dateStart !== undefined && dateS !== undefined ? formattedStartDate.slice(0, -5) + "00:00" :
                                dateStart !== undefined && dateS === undefined ? formattedStartDate.slice(0, -5) + "00:00" : 
                                dateStart === undefined && dateS === undefined ? formattedStartDate.slice(0, -5) + "00:00" :
                                dateStart === undefined && dateS !== undefined ?  formattedStartDateFromGoCounnt && formattedStartDateFromGoCounnt.slice(0, -5) + "00:00":
                                formattedStartDateFromGoCounnt && formattedStartDateFromGoCounnt.slice(0, -5).includes('Invalid') ? formattedStartDate.slice(0, -5) + "00:00" : 
                                formattedStartDate.slice(0, -5) + "00:00",
                                endDate: dateEnd !== undefined && dateE !== undefined ? formattedEndTime.slice(0, -5) + "23:59" :
                                dateEnd !== undefined && dateE === undefined ? formattedEndTime.slice(0, -5) + "23:59" : 
                                dateEnd === undefined && dateE === undefined ? formattedEndTime.slice(0, -5) + "23:59" :
                                dateEnd === undefined && dateE !== undefined ? formattedEndTimeFromGoCounnt && formattedEndTimeFromGoCounnt.slice(0, -5) + "23:59":
                                formattedEndTimeFromGoCounnt && formattedEndTimeFromGoCounnt.slice(0, -5).includes('Invalid') ? formattedEndTime.slice(0, -5) + "23:59" : 
                                formattedEndTime.slice(0, -5) + "23:59",
                                address: objectAddress,
                                searchValue,
                                isDeleted,
                                objectName: object,
                                pultNumber: pult ? pult : pultState,
                                eventType: event,
                                gbr,
                                alarmKindId: alarmKind,
                                // chopName: chop,
                                chopName: chop,
                                isWebReport : true,
                                isCancled: cancled,
                                territoryId: territoryId,
                                isLessTwoHours: isLessTwoHours,
                                isNoDeparture: isNoDeparture,
                            }
                        }]
                    })
                } else {
                getReportXlSX({
                    reportName: (hrefName === 'raports' && isExternal) ? 'ExternalRaportsReport' : xlsxFlag,
                    fileExtension: 'xlsx',
                    paramList: [{
                        name: 'request',
                        value: {
                            pcoId,
                            gbrId,
                            isExternal: isExternal,
                            startDate: hrefName === 'goCountGroup' ? oneDate?.toISOString() : timeStart === undefined ? formattedStartDate.slice(0, -5) + "00:00" : formattedStartDate,
                            endDate: timeEnd === undefined ? formattedEndTime.slice(0, -5) + "23:59" : formattedEndTime,
                            address: objectAddress,
                            searchValue,
                            isDeleted,
                            objectName: object,
                            pultNumber: pult,
                            eventType: event,
                            gbr,
                            alarmKindId: alarmKind,
                            chopName: isExternal ? chop : undefined,
                            isWebReport : true,
                            isCancled: cancled,
                            territoryId: territoryId,
                            isLessTwoHours: isLessTwoHours,
                            isNoDeparture: isNoDeparture
                        }
                    }]
                })
            }

    }

    const searchHandler = () => {       
        if (flag === 'goCountGroup') {
            if (!gbrId || !oneDate) {
                openNotification({
                    type: 'warning',
                    message: 'Выберите нужную дату и ГБР'
                })
            } else { 
                request({
                    startDate: timeStart === undefined ? formattedStartDate.slice(0, -5) + "00:00" : formattedStartDate,
                    endDate: timeEnd === undefined ? formattedEndTime.slice(0, -5) + "23:59" : formattedEndTime,
                    gbrId
                })
            }
        }else if  ( flag === 'raportMonth') {
        if(activeTable === false){
            debugger
            if (!gbrId || !oneDate) {
                openNotification({
                    type: 'warning',
                    message: 'Выберите нужную дату и ГБР'
                })
            } else { 
                request({
                    startDate: timeStart === undefined ? formattedStartDate.slice(0, -5) + "00:00" : formattedStartDate,
                    endDate: timeEnd === undefined ? formattedEndTime.slice(0, -5) + "23:59" : formattedEndTime,
                    gbrId
                })
            }
        } else {
            if(!territoryId || !oneDate){
                openNotification({
                    type: 'warning',
                    message: 'Выберите нужную дату и территорию'
                })
                debugger
            } else {
                request({
                    startDate: timeStart === undefined ? formattedStartDate.slice(0, -5) + "00:00" : formattedStartDate,
                    endDate: timeEnd === undefined ? formattedEndTime.slice(0, -5) + "23:59" : formattedEndTime,
                    territoryId
                })
            }
        }
        } else if (flag === 'journalActions') {  
            request({
                pcoId,
                gbrId,
                startDate: timeStart === undefined ? formattedStartDate.slice(0, -5) + "00:00" : formattedStartDate,
                endDate: timeEnd === undefined ? formattedEndTime.slice(0, -5) + "23:59" : formattedEndTime,
                address: objectAddress,
                searchValue: pult,
                isDeleted,
                isExternal,
                objectName: object,
                // pultNumber: pult,
                eventType: event,
                alarmKindId: alarmKind,
                gbr,
                chopName: chop,
                isWebReport : true
            })
        }else if (flag === 'settings'){
            request({
                searchValue
            })
        }else if(flag === 'dislocation'){
            request({
                pcoId,
                gbrId,
                startDate: timeStart === undefined ? formattedStartDate.slice(0, -5) + "00:00" : formattedStartDate,
                endDate: timeEnd === undefined ? formattedEndTime.slice(0, -5) + "23:59" : formattedEndTime,
                address: objectAddress,
                searchValue,
                isDeleted,
                isExternal,
                objectName: object,
                pultNumber: pult,
                eventType: event,
                alarmKindId: alarmKind,
                gbr,
                chopName: chop,
                isWebReport : true,
                isCancled: cancled,
                isLessTwoHours: isLessTwoHours,
                isNoDeparture: isNoDeparture
            })
        }else if(flag === 'GoCountRaport') {
            request({
            startDate: timeStart === undefined ? formattedStartDate.slice(0, -5) + "00:00" : formattedStartDate,
            endDate: timeEnd === undefined ? formattedEndTime.slice(0, -5) + "23:59" : formattedEndTime,
            gbrId,
            territoryId,
            isGroupCount,
            isCancled: false,
            minDepartureCount: minDepartureCount === undefined ? undefined :  Number(minDepartureCount)
            })
        } else if (flag === 'goCountRaportWithReact') {
            request({
                pcoId,
                isOnlyExternal: isExternal,
                startDate: timeStart === undefined ? formattedStartDate.slice(0, -5) + "00:00" : formattedStartDate,
                endDate: timeEnd === undefined ? formattedEndTime.slice(0, -5) + "23:59" : formattedEndTime,
            })
        } 
        else if(flag === 'raports'){
            // if(pultState === null){
            console.log(timeStart)
            console.log(timeEnd)
            console.log(dateS)
            console.log(dateE)
                request({
                    pcoId,
                    gbrId,
                    startDate: dateStart !== undefined && dateS !== undefined ? formattedStartDate.slice(0, -5) + "00:00" :
                    dateStart !== undefined && dateS === undefined ? formattedStartDate.slice(0, -5) + "00:00" : 
                    dateStart === undefined && dateS === undefined ? formattedStartDate.slice(0, -5) + "00:00" :
                    dateStart === undefined && dateS !== undefined ? formattedStartDateFromGoCounnt && formattedStartDateFromGoCounnt.slice(0, -5) + "00:00":
                    formattedStartDate,
                    endDate: dateEnd !== undefined && dateE !== undefined ? formattedEndTime.slice(0, -5) + "23:59" :
                    dateEnd !== undefined && dateE === undefined ? formattedEndTime.slice(0, -5) + "23:59" : 
                    dateEnd === undefined && dateE === undefined ? formattedEndTime.slice(0, -5) + "23:59" :
                    dateEnd === undefined && dateE !== undefined ? formattedEndTimeFromGoCounnt && formattedEndTimeFromGoCounnt.slice(0, -5) + "23:59":
                    formattedEndTime,
                    address: objectAddress,
                    searchValue,
                    isDeleted,
                    isExternal,
                    objectName: object,
                    pultNumber: pult ? pult : pultState,
                    eventType: event,
                    alarmKindId: alarmKind,
                    gbr,
                    chopName: isExternal ? chop : undefined,
                    isWebReport : true,
                    isCancled: cancled
                })
            // }else{
            //     request({
            //         pcoId,
            //         gbrId,
            //         startDate: timeStart === undefined ? formattedStartDateFromGoCounnt.slice(0, -5) + "00:00" : formattedStartDate,
            //         endDate: timeEnd === undefined ? formattedEndTimeFromGoCounnt.slice(0, -5) + "23:59" : formattedEndTime,
            //         address: objectAddress,
            //         searchValue,
            //         isDeleted,
            //         isExternal,
            //         objectName: object,
            //         pultNumber: pultState,
            //         eventType: event,
            //         alarmKindId: alarmKind,
            //         gbr,
            //         chopName: chop,
            //         isWebReport : true,
            //         isCancled: cancled
            //     })
            // }
        
        } else {
            request({
                pcoId,
                gbrId,
                startDate: timeStart === undefined ? formattedStartDate.slice(0, -5) + "00:00" : formattedStartDate,
                endDate: timeEnd === undefined ? formattedEndTime.slice(0, -5) + "23:59" : formattedEndTime,
                address: objectAddress,
                searchValue,
                isDeleted,
                isExternal,
                objectName: object,
                pultNumber: pult,
                eventType: event,
                alarmKindId: alarmKind,
                gbr,
                chopName: chop,
                isWebReport : true,
                isCancled: cancled
            })
        } 
    }
    // const formattedTimeStartFromGoCounnt = moment(timeStart).startOf('m').format("HH:mm");
    // const formattedTimeEndFromGoCounnt = moment(timeEnd).startOf('m').format("HH:mm");
    // const formattedStartDateFromGoCounnt = moment(DateStartFilter).startOf('m').format("YYYY-MM-DDTHH:mm").slice(0, -5) + formattedTimeStart;
    // const formattedEndTimeFromGoCounnt
    useEffect(() => {
        if(( flag === 'raportMonth'))
            {

            } else
        if(pultState !== null) {
            console.log(timeStart)
            console.log(DateStartFilter)
            console.log(formattedStartDateFromGoCounnt)
            console.log(formattedStartDate)
            request({
                pcoId,
                gbrId,
                startDate: formattedStartDateFromGoCounnt && formattedStartDateFromGoCounnt.slice(0, -5).includes('Invalid') ? formattedStartDate.slice(0, -5) + "00:00" : 
                formattedStartDateFromGoCounnt && formattedStartDateFromGoCounnt.slice(0, -5) + "00:00",
                // timeStart === undefined ? formattedStartDateFromGoCounnt.slice(0, -5) + "00:00" : formattedStartDateFromGoCounnt,
                endDate: formattedEndTimeFromGoCounnt && formattedEndTimeFromGoCounnt.slice(0, -5).includes('Invalid') ? formattedEndTime.slice(0, -5) + "23:59" : 
                formattedEndTimeFromGoCounnt && formattedEndTimeFromGoCounnt.slice(0, -5) + "23:59",
                address: objectAddress,
                searchValue,
                isDeleted,
                isExternal,
                objectName: object,
                pultNumber: pultState,
                eventType: event,
                alarmKindId: alarmKind,
                gbr,
                chopName: chop,
                isWebReport : true,
                isCancled: cancled
            })
        }
    }, [])



    useEffect(() => {
        switch (flag) {
            case 'goCountGroup': {
                getGBRS();
                break
            }
            case 'raportMonth': {
                getGBRS();
                getPCO()
                getSettings('get', {})
                break
            }
            case 'raports': {
                getGBRS();
                getEventTypes();
                getAlarmKindTypes();
                getReportChops();
                break
            }
            case 'goCountChop': {
                getReportChops();
                break
            }
            case 'goCountRaport': {
                getGBRS();
                break
            }
            case 'goCountRaportWithReact': {
                getPCO()
                break
            }
            case 'GoCountRaport':{
                getGBRS()
                getPCO()
                getSettings('get', {})
                break
            }
            case 'settings':{
                getGBRS()
                break
            }
            case '':{
                getSettings('get', {})
                break
            }
            // case 'journalActios': {

            // }
            default:
                break
        }
    }, [])

    // Функлция для Александра, потому что не хочет давать id
    const funcForSimpleSelect = (id: number) => {
        let name = '';
        chopReports.filter((element: { id: number, name: string }) => {
            if (element.id === id) {
                name = element.name
            }
        })
        setChop(name)
    }

    const debounceObjectsSearch = useDebouncedFunction((value: string) => {
        getObjectsOfRaports({ searchValue: value })
    }, 1000)

    const debouncePultsSearch = useDebouncedFunction((value: string) => {
        getPultsOfRaports({ searchValue: value })
    }, 1000)

    const debounceAddressesSearch = useDebouncedFunction((value: string) => {
        getAddressesOfRaports({ searchValue: value })
    }, 1000)

    const changeGBRForRaportMonth = (val: string) => {
        setGbrId(val)
        setTerritoryId(undefined)
        setActiveTable(false)
    }
    const changePCOForRaportMonth = (val: string) => {
        setTerritoryId(val)
        setGbrId(undefined)
        setActiveTable(true)
    }

    const changeGBRForGoCount = (val: string) => {
        setGbrId(val)
        setTerritoryId(undefined)
        // setActiveTable(false)
    }
    const changePCOForGoCount = (val: string) => {
        setTerritoryId(val)
        setGbrId(undefined)
        // setActiveTable(true)
    }
    const changeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }

    const { Search } = Input;
    // console.log(pult)
    const newDateS = dateS && JSON.parse(dateS)
    const NewDateE = dateE && JSON.parse(dateE)
    return <div className={s.block}>
        {flag === 'raports' && <div className={s.row}>
            <div style={{ width: '25%' }} className={s.title}>
                Быстрый поиск:
            </div>
            <SimpleInput
                onChange={(e) => setSearchValue(e.currentTarget.value)}
                value={searchValue}
            />
        </div>}
        { flag !== 'settings' ?
        <div className={ flag !== 'GoCountRaport' ? s.row_alt : s.row_alt_alt}>
            <div className={s.title_alt}>
                Даты:
            </div>
            {/* flag !== 'goCountGroup' ? */}
            { flag === 'GoCountRaport'  ?
                <div className={s.row}>
                    <div>
                    {
                        isMonth ?
                        <MonthPicker
                            width={'100%'}
                            onChange={(value: any) => {
                                if (value?.length === 2) {
                                    console.log('bbbb')
                                    // setDateStart(moment(value[0]).format())
                                    // setDateEnd(moment(value[1]).format())
                                    setDateStart(value[0])
                                    setDateEnd(value[1])
                               
                       
                                } else {
                                    setDateStart(undefined)
                                    setDateEnd(undefined)
                                  
                                
                                }
                            }}
                            value={undefined}
                            rangeValues={dateEnd && dateStart ? [dateStart, dateEnd] : undefined}
                        /> 
                        :
                        <RangeDatePicker
                            width={'100%'}
                            onChange={(value: any) => {
                                if (value?.length === 2) {
                                    // setDateStart(moment(value[0]).format())
                                    // setDateEnd(moment(value[1]).format())
                                    console.log('aaaaaaaaaaaaaa')
                                    setDateStartFilter && setDateStartFilter(value[0])
                                    setDateEndFilter && setDateEndFilter(value[1])
                                    setDateStart(value[0])
                                    setDateEnd(value[1])
                               
                                } else {
                                    console.log('aaaaaaaaaaasdsdsaaa')
                                    debugger
                                    setDateStart(undefined)
                                    setDateEnd(undefined)
                                    setDateStartFilter && setDateStartFilter(undefined)
                                    setDateEndFilter && setDateEndFilter(undefined)
                                 
                                }
                            }}
                            value={undefined}
                            rangeValues={dateEnd && dateStart ? [dateStart, dateEnd] : undefined}
                        />
                    }
                    </div>
                
                    <div className={s.column}>

                        <div className={s.column_row}>
                            <SimpleCheckbox
                                value={isMonth}
                                onChange={() => setIsMonth(!isMonth)}
                            />
                            <div style={{whiteSpace: 'nowrap'}} className={s.title_alt}>
                                По месяцам
                            </div>
                        </div>

                        <div className={s.column_row}>
                            <SimpleCheckbox
                                value={isGroupCount}
                                onChange={() => setIsGroupCount(!isGroupCount)}
                            />
                            <div style={{whiteSpace: 'nowrap'}} className={s.title_alt}>
                                По группам 
                            </div>
                             
                        </div>


                    </div>
                </div>
                
            :

            flag !== 'raportMonth' ?
            <RangeDatePicker
                width={'80%'}
                onChange={(value: any) => {
                    if (value?.length === 2) {
                        // setDateStart(moment(value[0]).format())
                        // setDateEnd(moment(value[1]).format())
                        console.log('bbbbsdsdds')
                        setDateStartFilter && setDateStartFilter(value[0])
                        setDateEndFilter && setDateEndFilter(value[1])
                        setDateStart(value[0])
                        setDateEnd(value[1])
                   
                    } else {
                        setDateStart(undefined)
                        setDateEnd(undefined)
                    
                    }
                }}
                value={undefined}
                rangeValues={dateEnd && dateStart ? [dateStart, dateEnd] : newDateS && NewDateE ? [newDateS, NewDateE] :  undefined}
            />
            //////rfrf
            :
            <MonthPicker
                width={'80%'}
                onChange={(value: any) => {
                    if (value?.length === 2) {
                        // setDateStart(moment(value[0]).format())
                        // setDateEnd(moment(value[1]).format())
                        console.log('bbbbsdsddsssssss')
                        setDateStart(value[0])
                        setDateEnd(value[1])
                    } else {
                        setDateStart(undefined)
                        setDateEnd(undefined)
                    }
                }}
                value={undefined}
                rangeValues={dateEnd && dateStart ? [dateStart, dateEnd] : undefined}
            />
            }
            {/* :
                // <SimpleDatepicker
                //     width={'80%'}
                //     onChange={setOneDate}
                //     value={oneDate}
                /> */}
        </div>
        : 
        <div className={s.row}>
            <div className={s.title}>
                Поиск:
            </div>
            <Search placeholder="Поиск по территории..." loading={settingsLoading && settingsLoading} size="large" 
            value={searchValue}
            onChange={(e) => changeSearchValue(e)}
            onSearch={() => searchHandler()}/>
            
        </div>
        }
        { flag !== 'raportMonth' && showTime === true && !isMonth ?
        <div className={flag !== 'GoCountRaport' ? s.row : s.row_alt_alt}>
            
            <div className={flag !== 'GoCountRaport' ? s.title : s.title_alt}>
                Время:
            </div>
            {/* flag !== 'goCountGroup' ? */}
            <TimePicker.RangePicker 
            style={{
                width: '80%'}}
                            onChange={(value: any) => {
                                if (value?.length === 2) {
                                    // setDateStart(moment(value[0]).format())
                                    // setDateEnd(moment(value[1]).format())
                                    setTimeStart(value[0])
                                    setTimeEnd(value[1])
                                } else {
                                    setTimeStart(undefined)
                                    setTimeEnd(undefined)
                                }
                            }}
            defaultValue={[moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')]}
            format={'HH:mm'}
            // rangeValues={timeEnd && timeStart ? [timeStart, timeEnd] : undefined}
            // autoFocus= {true}
            />
            {/* :
                // <SimpleDatepicker
                //     width={'80%'}
                //     onChange={setOneDate}
                //     value={oneDate}
                /> */}
        </div>
        :
        <></>
        }
        {flag === 'dislocation' && <div className={s.row}>
            <div style={{ width: '49%', justifyContent: 'flex-start', marginBottom: '0px' }} className={s.row}>
                <div className={s.title}>
                    Менее 2-х часов
                </div>
                <SimpleCheckbox
                    value={isLessTwoHours}
                    onChange={() => setIsLessTwoHours(!isLessTwoHours)}
                />
            </div>
            <div style={{ width: '49%', justifyContent: 'flex-start' }} className={s.row}>
                <div className={s.title}>
                    Безвыездно
                </div>
                <SimpleCheckbox
                    value={isNoDeparture}
                    onChange={() => setIsNoDeparture(!isNoDeparture)}
                />
            </div>
        </div>}
        {flag === 'raports' && <div className={s.row}>
            <div style={{ width: '49%' }}>
                <div style={{ width: '100%' }} className={s.title}>
                    Объект:
                </div>
                <SimpleSelect
                    showSearch
                    onSearch={(value) => debounceObjectsSearch(value)}
                    onChange={(e) => {
                        
                        let name: string = '';
                        objectsOfRaports.filter((element: { id: any; name: string }) => {
                            if (element.id === e) {
                                name = element.name
                            }
                        })
                        setObject(name)
                    }}
                    value={object}
                    items={objectsOfRaports || []}
                    loading={objectsOfRaportsLoading}
                />
            </div>
            <div style={{ width: '49%' }}>
                <div style={{ width: '100%' }} className={s.title}>
                    Пультовый номер:
                </div>
                <SimpleSelect
                    showSearch
                    onSearch={(value) => debouncePultsSearch(value)}
                    onChange={(e) => {
                        
                        let name: string = '';
                        pultsOfRaports.filter((element: { id: any; name: string }) => {
                            if (element.id === e) {
                                name = element.name
                            }
                        })
                        setPult(name)
                    }}
                    //
                    value={pult !== undefined ? pult : pultState === null ? pult : pultState}
                    items={pultsOfRaports || []}
                    loading={pultsOfRaportsLoading}
                />
                {/* <SimpleInput
                    onChange={(e) => setPult(e.currentTarget.value)}
                    value={pult}
                /> */}
            </div>
        </div>}
        {flag === 'ExternalDepartureGeneralCountReport' && <div className={s.row}>
            <div style={{display:'flex', flexDirection:'row', width: '100%'}}>
                <div className={s.title} >
                    Объект:
                </div>
                <SimpleSelect
                    width={'80%'}
                    showSearch
                    onSearch={(value) => debounceObjectsSearch(value)}
                    onChange={(e) => {
                        
                        let name: string = '';
                        objectsOfRaports.filter((element: { id: any; name: string }) => {
                            if (element.id === e) {
                                name = element.name
                            }
                        })
                        setObject(name)
                    }}
                    value={object}
                    items={objectsOfRaports || []}
                    loading={objectsOfRaportsLoading}
                />
            </div>
            </div>
        }
        {flag === 'raports' && <div className={s.row}>
            <div style={{ width: '49%' }}>
                <div style={{ width: '100%' }} className={s.title}>
                    Адрес объекта:
                </div>
                <SimpleSelect
                    showSearch
                    onSearch={(value) => debounceAddressesSearch(value)}
                    onChange={(e) => {
                        
                        let name: string = '';
                        addressOfRaports.filter((element: { id: any; name: string }) => {
                            if (element.id === e) {
                                name = element.name
                            }
                        })
                        setObjectAddress(name)
                    }}
                    value={objectAddress}
                    items={addressOfRaports || []}
                    loading={addressOfRaportsLoading}
                />
                {/* <SimpleInput
                    onChange={(e) => setObjectAddress(e.currentTarget.value)}
                    value={objectAddress}
                /> */}
            </div>
            <div style={{ width: '49%' }}>
                <div style={{ width: '100%' }} className={s.title}>
                    ГБР:
                </div>
                <SimpleSelect
                    showSearch
                    onChange={(e) => {
                        
                        let name: string = '';
                        gbrList.filter(element => {
                            if (element.id === e) {
                                name = element.name
                            }
                        })
                        setGbr(name)
                    }}
                    value={gbr}
                    items={gbrList || []}
                    loading={gbrListLoading}
                />
            </div>
        </div>}
        {flag === 'raports' && <div className={s.row}>
          <div style={{width: '49%'}}>
            <div className={s.title}>
              Тип события:
            </div>
            <SimpleSelect
              showSearch
              width={'80%'}
              onChange={setEvent}
              value={event}
              items={eventTypes || []}
              loading={eventTypesLoading}
            />
          </div>

            <div style={{width: '49%'}}>
                <div className={s.title}>
                  Тип тревоги:
                 </div>
                <SimpleSelect
                    showSearch
                    width={'80%'}
                    onChange={(value) => {
                        setAlarmKind(value)
                        setIsExternal(false)
                    }}
                    value={alarmKind}
                    items={alarmKindTypes || []}
                    loading={alarmKindTypesLoading}
                />
            </div>
        </div>}

        {flag === 'goCountChop'    &&  <div className={s.row}>
        <div style={{width: '49%'}}>
          <div className={s.title}>
            ЧОП:
          </div>
          <SimpleSelect
            showSearch
            width={'80%'}
            onChange={funcForSimpleSelect}
            value={chop}
            items={chopReports || []}
            loading={chopReportsLoadnig}
            />
            </div>
        </div>}

        {flag === 'raports'   && isExternal && <div className={s.row}>
        <div style={{width: '49%'}}>
          <div className={s.title}>
            ЧОП:
          </div>
          <SimpleSelect
            showSearch
            width={'80%'}
            onChange={funcForSimpleSelect}
            value={chop}
            items={chopReports || []}
            loading={chopReportsLoadnig}
            />
            </div>
        </div>}

        {flag === 'raports' &&  <div className={s.row}>
            <div style={{ width: '49%', justifyContent: 'flex-start', marginBottom: '0px' }} className={s.row}>
                <div className={s.title}>
                    Показать удаленные
                </div>
                <SimpleCheckbox
                    value={isDeleted}
                    onChange={() => setIsDeleted(!isDeleted)}
                />
            </div>
            <div style={{ width: '49%', justifyContent: 'flex-start' }} className={s.row}>
                <div className={s.title}>
                    Реагирование
                </div>
                <SimpleCheckbox
                    value={isExternal}
                    onChange={() => {
                        setIsExternal(!isExternal)
                        setAlarmKind(undefined)
                    }}
                />
            </div>
        </div>}

        {flag === 'goCountGroup' && <div className={s.row}>
            <div className={s.title}>
                ГБР:
            </div>
            <SimpleSelect
                showSearch
                width={'80%'}
                onChange={setGbrId}
                value={gbrId}
                items={gbrList || []}
                loading={gbrListLoading}
            />
        </div>}
        {flag === 'raportMonth' && <div className={s.row}>
            <div className={s.title}>
                ГБР:
            </div>
            <SimpleSelect
                showSearch
                width={'80%'}
                onChange={(val) => changeGBRForRaportMonth(val)}
                value={gbrId}
                items={gbrList || []}
                loading={gbrListLoading}
            />
        </div>}
        {flag === 'GoCountRaport' && 
        <div className={s.row}>
            <div style={{ width: '49%', justifyContent: 'flex-start', marginBottom: '0px' }} >
                  <div className={s.title} style={{ width: '100%' }}>
                  Территория:
            </div>
            <SimpleSelect
                showSearch
                width={'80%'}
                onChange={(val) => changePCOForGoCount(val)}
                value={territoryId}
                items={settings || []}
                loading={pcoListLoading}
            />
            
            <div style={{ width: '49%', justifyContent: 'flex-start', marginBottom: '0px' }}>
             <div className={s.title} style={{ width: '100%' }}>
                ГБР:
            </div>
            <SimpleSelect
                showSearch
                width={'100%'}
                onChange={(val) => changeGBRForGoCount(val)}
                value={gbrId}
                items={gbrList || []}
                loading={gbrListLoading}
            />
            </div>
            </div>
            <div className={s.count} style={{marginBottom: '80px', marginRight:'50px'}}>
            <div className={s.title} style={{ width: '100%' }}>
                Количество:
            </div>
                <Input 
                    value={minDepartureCount} 
                    onChange={(e) => setMinDepartureCount(e.target.value)}
                />
            </div>
            </div>
            
            }
            {flag === 'raportMonth' && <div className={s.row}>
            <div className={s.title}>
                Территория:
            </div>
            <SimpleSelect
                showSearch
                width={'80%'}
                onChange={(val) => changePCOForRaportMonth(val)}
                value={territoryId}
                items={settings || []}
                loading={pcoListLoading}
            />
            </div>}
        {flag === 'goCountRaportWithReact' &&
          <>
            <div className={s.row}>
              <div className={s.title}>
                ПЦО:
              </div>
              <SimpleSelect
                showSearch
                width={'80%'}
                onChange={(value) => {
                    setPcoId(value)
                    setIsExternal(false)
                }}
                value={pcoId}
                items={pcoList || []}
                loading={pcoListLoading}
              />
            </div>
            <div style={{width: '49%', justifyContent: 'flex-start'}} className={s.row}>
              <div className={s.title}>
                Реагирование
              </div>
              <SimpleCheckbox
                value={isExternal}
                onChange={() => {
                    setIsExternal(!isExternal)
                    setPcoId(undefined)
                }}
              />
            </div>
          </>
        }
        {/* {flag === 'raportMonth' &&
            <div className={s.row}>
                <div className={s.title}>
                     ПЦО:
                </div>
                <SimpleSelect
                    showSearch
                    width={'80%'}
                    onChange={(val) => changePCOForRaportMonth(val)}
                    value={pcoId}
                    items={pcoList || []}
                    loading={pcoListLoading}
            />
            </div>} */}
        {flag === 'journalActions' &&
            <div className={s.row}>
                    <div style={{ width: '49%' }}>
                    <div style={{ width: '100%' }} className={s.title}>
                        Пультовый номер:
                    </div>
                    <SimpleSelect
                        showSearch
                        onSearch={(value) => debouncePultsSearch(value)}
                        onChange={(e) => {
                            
                            let name: string = '';
                            pultsOfRaports.filter((element: { id: any; name: string }) => {
                                if (element.id === e) {
                                    name = element.name
                                }
                            })
                            setPult(name)
                        }}
                        value={pult}
                        items={pultsOfRaports || []}
                        loading={pultsOfRaportsLoading}
                    />
                    {/* <SimpleInput
                        onChange={(e) => setPult(e.currentTarget.value)}
                        value={pult}
                    /> */}
                </div>
                </div>}
            
            {flag === 'ExternalDepartureCount' &&
                 <div style={{ width: '49%', justifyContent: 'flex-start', marginBottom: '0px' }} className={s.row}>
                <div>
                <div style={{display: "flex", flexDirection: 'row'}}>
                 <div className={s.title}>
                     Показать отменённые
                 </div>
                 <SimpleCheckbox
                     value={cancled}
                     onChange={() => setCancled(!cancled)}
                 />
                 </div>
                  <div style={{display: "flex",  width: '100%', justifyContent: 'flex-start', marginBottom: '0px', flexDirection: 'row' }} >
                 <div className={s.title} style={{ width: '30%' }}>
                    Территория:
                 </div>
                 <SimpleSelect
                    showSearch
                    width={'500px'}
                    onChange={(val) => changePCOForGoCount(val)}
                    value={territoryId}
                    items={settings || []}
                    loading={pcoListLoading}
                 />
                 </div>
                 </div>
             </div>
            }
            {/* {flag === 'GoCountRaport' &&
                <div className={s.row}>
                    <div className={s.title}>
                        ПЦО:
                    </div>
                    <SimpleSelect
                    showSearch
          width={'80%'}
          onChange={setPcoId}
          value={pcoId}
          items={pcoList || []}
          loading={pcoListLoading}
      />
      </div>
            } */}
        <div className={s.buttons}>
            <ListItem className={s.cancel} button onClick={resetFiltersHandler}>
                Очистить
            </ListItem>
            <ListItem className={s.find} button onClick={searchHandler}>
                Найти
            </ListItem>
            {buttonFlag === "none" ? <></> :
                        <ListItem className={s.cancel} button onClick={downloadXLSX}>
                        Загрузить в XLSX
                    </ListItem>
            }
            {flag === "settings" ? 
                <ListItem className={s.cancel} button onClick={() => setOpenModal && setOpenModal({open: true, flag: 'put'})}>
                    Добавить
                </ListItem>
                :
                <></>
            }
        </div>
    </div>
})